






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { pluralize } from '@/services/functions'
import { DateManager } from '@/services/Managers'

@Component
export default class CharacterInfoCard extends Vue {
  @Prop() character

  pluralize = pluralize

  get levelStats() {
    const { character } = this

    return [
      { title: 'Level', value: character.level },
      { title: 'Job Level', value: character.jobLevel },
      { title: 'Hero Level', value: character.heroLevel }
    ]
  }

  getRemainingTime(date) {
    return DateManager.getRemainingTime(date)
  }

  get Act4Stats() {
    const { act4 } = this.character.pvpStats

    return [
      { title: 'Act4 Kills', value: act4.act4Kill },
      { title: 'Act4 Deaths', value: act4.act4Dead },
      { title: 'Act4 Points', value: act4.act4Points }
    ]
  }
}
